<template>
  <NoteSection
    label="BMI"
  >
    <text-field
      v-model="height_feet"
      class="col-sm-3"
      label="Height Feet"
      placeholder="Feet"
      numeric
      mask="#"
      rules="numberRange(1,8)"
      :required="!!height_inches"
    ></text-field>
    <text-field
      v-model="height_inches"
      class="col-sm-3"
      label="Height Inches"
      placeholder="Inches"
      numeric
      :mask="[/[0-9|.]/, /[0-9|.]/, /[0-9|.]/, /[0-9]/, /[0-9]/]"
      rules="numberRange(0,11.99)"
      :suffix="heightMeters && `(${heightMeters} m)`"
      :required="!!height_feet"
    ></text-field>
    <text-field
      v-model="weight_pounds"
      class="col-sm-4"
      label="Weight Pounds"
      placeholder="Pounds"
      numeric
      :mask="[/[0-9|]/, /[0-9|.]/, /[0-9|.]/, /[0-9|.]/, /[0-9|.]/, /[0-9]/, /[0-9]/]"
      rules="cantBeValue('Weight',0)"
      :suffix="weightKg && `(${weightKg} kg)`"
    ></text-field>
    <text-field
      class="col-sm-2 no-border suffix-bold"
      :class="bmiColor"
      label="BMI:"
      :suffix="computedBMI"
      readonly
      disabled
    ></text-field>
  </NoteSection>
</template>

<script>
export default {
  props: {
    heightFeet: {
      type: String,
      default: null,
    },
    heightInches: {
      type: String,
      default: null,
    },
    weightPounds: {
      type: String,
      default: null,
    },
    bmi: {
      type: String,
      default: null,
    },
    dob: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      height_feet: this.heightFeet,
      height_inches: this.heightInches,
      weight_pounds: this.weightPounds,
    }
  },
  computed: {
    heightMeters() {
      return (this.height_feet && this.height_inches)
        && ((Math.floor(this.height_feet) * 12 + Math.floor(this.height_inches)) * 0.0254).toFixed(2)
    },
    weightKg() {
      return this.weight_pounds
        && (this.weight_pounds * 0.453592).toFixed(1)
    },
    computedBMI() {
      return this.heightMeters && this.weightKg
        && (this.weightKg / (this.heightMeters ** 2)).toFixed(1).toString()
    },
    bmiColor() {
      if (!!this.computedBMI && typeof this.dob !== 'undefined') {
        const age = this.$custom.ageInYears(this.dob)

        if (age >= 21) {
          if (this.computedBMI >= 18.5 && this.computedBMI <= 25) return 'normal'
          if ((this.computedBMI >= 17 && this.computedBMI <= 18.5) || (this.computedBMI >= 25 && this.computedBMI <= 30)) return 'mild'
          if ((this.computedBMI >= 16 && this.computedBMI <= 17) || (this.computedBMI >= 30 && this.computedBMI <= 35)) return 'moderate'

          return 'severe'
        }
      }

      return ''
    },
  },
  watch: {
    height_feet() {
      this.$emit('update:height-feet', this.height_feet)
      this.$emit('update:bmi', this.computedBMI)
    },
    height_inches() {
      this.$emit('update:height-inches', this.height_inches)
      this.$emit('update:bmi', this.computedBMI)
    },
    weight_pounds() {
      this.$emit('update:weight-pounds', this.weight_pounds)
      this.$emit('update:bmi', this.computedBMI)
    },
  },
  methods: {
  },
}
</script>
