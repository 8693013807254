<template>
  <NoteSection
    label="History of Present Illness"
  >
    <text-area
      v-model="present_illness_history"
      class="col-sm-12"
      label="Enter history of present illness..."
      required
      ai
      counter="10400"
      maxlength="10400"
      word-count="7"
      @click:prepend="generateIllnessHistory"
      @input="$emit('input', present_illness_history)"
    ></text-area>
  </NoteSection>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    medicalHistoryList: {
      type: Array,
      default: () => [],
    },
    medicalHistoryOther: {
      type: String,
      default: null,
    },
    riskFactorList: {
      type: Array,
      default: () => [],
    },
    riskFactorOther: {
      type: String,
      default: null,
    },
    woundList: {
      type: Array,
      default: null,
    },
    treatmentStatus: {
      type: Boolean,
      default: false,
    },
    patientDob: {
      type: String,
      default: null,
    },
    patientGender: {
      type: String,
      default: 'person',
    },
    covidAssessment: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      present_illness_history: this.value,
    }
  },
  computed: {
    age() {
      return this.$custom.ageInYears(this.patientDob)
    },
  },
  watch: {},
  methods: {
    generateNonCovidHPI(illnessHistory = '') {
      const medicalHistoryTitles = []
      if (this.medicalHistoryList) {
        this.medicalHistoryList.forEach(medicalHistory => {
          if (medicalHistory.title !== undefined) {
            medicalHistoryTitles.push(medicalHistory.title)
          }
        })
      }
      const riskFactorTitles = []
      if (this.riskFactorList) {
        this.riskFactorList.forEach(riskFactor => {
          if (riskFactor.title !== undefined) {
            riskFactorTitles.push(riskFactor.title)
          }
        })
      }
      const woundItems = []
      const dermItems = []
      const gTubeItems = []
      if (!this.$custom.isEmpty(this.woundList)) {
        this.woundList.forEach(wound => {
          if (wound.practice_type_id === 1) {
            woundItems.push(wound)
          } else if (wound.practice_type_id === 5) {
            gTubeItems.push(wound)
          } else if (wound.practice_type_id === 6) {
            dermItems.push(wound)
          }
        })
      }
      const woundLocations = []
      const dermLocations = []
      if (woundItems) {
        woundItems.forEach(wound => {
          if (wound.location !== null && wound.location?.location_text !== null) {
            woundLocations.push(wound.location?.location_text)
          }
        })
      }
      if (dermItems) {
        dermItems.forEach(derm => {
          if (derm.location !== null && derm.location?.location_text !== null) {
            dermLocations.push(derm.location?.location_text)
          }
        })
      }
      illnessHistory = this.$custom.randomString(['This is', 'The patient is'])
      illnessHistory += this.$custom.articleAge(this.age)
      if (this.patientGender) {
        illnessHistory += this.$custom.toLowerCase(this.patientGender)
      }
      if (medicalHistoryTitles.length > 0) {
        illnessHistory += ' '
        illnessHistory += this.$custom.randomString(['with a past medical history of', 'having a past medical history of', 'who has a past medical history of', 'with past medical history including'])
        illnessHistory += this.$custom.toListCommaAnd(medicalHistoryTitles, null, 'lc')
      }
      if (this.medicalHistoryOther && this.medicalHistoryOther?.length > 0) {
        if (medicalHistoryTitles.length > 0) {
          illnessHistory += ', and additionally, '
        }
        illnessHistory += this.$custom.textAreaFlat(this.medicalHistoryOther)
      }
      if (medicalHistoryTitles.length > 0 || (this.medicalHistoryOther && this.medicalHistoryOther?.length > 0)) {
        illnessHistory += '.'
      }
      illnessHistory += ' '
      if (riskFactorTitles.length === 0 && this.riskFactorOther && this.riskFactorOther?.length > 0) {
        illnessHistory += 'Risk factors: '
        illnessHistory += this.$custom.textAreaFlat(this.riskFactorOther)
      }
      if (riskFactorTitles.length > 0) {
        illnessHistory += '\n\n'
        if (riskFactorTitles.length > 1) {
          illnessHistory += this.$custom.randomString(['The following risk factors are noted:', 'The following risk factors have been noted:', 'These risk factors are noted:', 'These risk factors have been noted:'])
          illnessHistory += this.$custom.toListCommaAnd(riskFactorTitles, null, 'lc')
        }
        if (riskFactorTitles.length === 1) {
          const riskFactorTitle = riskFactorTitles.pop()
          illnessHistory += this.$custom.randomString([`The risk factor of ${riskFactorTitle} is noted`, `The risk factor of ${riskFactorTitle} has been noted`])
          illnessHistory += this.$custom.randomString(['', ' with this patient'])
        }
        if (this.riskFactorOther && this.riskFactorOther?.length > 0) {
          illnessHistory += this.$custom.randomString([', in addition to', ', as well as'])
          illnessHistory += this.$custom.textAreaFlat(this.riskFactorOther)
        }
      }

      illnessHistory += '.'

      return illnessHistory
    },
    generateCovidHistory(illnessHistory = '') {
      if (!(this.$custom.isObjectEmpty(this.covidAssessment)) && (this.covidAssessment?.have_cough !== null || this.covidAssessment?.have_fever !== null || this.covidAssessment?.have_weakness !== null)) {
        const covidYesItems = []
        const covidNoItems = []
        if (this.covidAssessment?.have_cough === true) {
          covidYesItems.push('a cough')
        }
        if (this.covidAssessment?.have_cough === false) {
          covidNoItems.push('cough')
        }
        if (this.covidAssessment?.have_fever === true) {
          covidYesItems.push('a fever')
        }
        if (this.covidAssessment?.have_fever === false) {
          covidNoItems.push('fever')
        }
        if (this.covidAssessment?.have_weakness === true) {
          covidYesItems.push('weakness/lethargy')
        }
        if (this.covidAssessment?.have_weakness === false) {
          covidNoItems.push('weakness/lethargy')
        }
        illnessHistory += '\n\n'
        illnessHistory += 'A comprehensive assessment of the '
        illnessHistory += this.$custom.randomString(["patient's health", 'health of the patient', 'health of this patient'])
        illnessHistory += 'was '
        illnessHistory += this.$custom.randomString(['performed', 'conducted', 'carried out'])
        illnessHistory += this.$custom.randomString(['', 'today'])
        illnessHistory += 'to rule out any '
        illnessHistory += this.$custom.randomString(['symptoms of COVID-19', 'COVID-19 symptoms', 'symptoms congruent with those of COVID-19'])
        illnessHistory += ', prior to consultation.  Patient was '
        illnessHistory += this.$custom.randomString(['found to have', 'determined to have', 'noted as having'])
        illnessHistory += this.$custom.toListCommaAnd(covidYesItems, null, 'lc')
        if (covidNoItems.length > 0) {
          if (covidYesItems.length > 0) {
            illnessHistory += ', but '
          }
          illnessHistory += 'no '
          illnessHistory += this.$custom.toListCommaOr(covidNoItems, null, 'lc')
        }
        illnessHistory += '.'
      }

      return illnessHistory
    },
    generateIllnessHistory() {
      if (!this.treatmentStatus) {
        this.$root.confirm({
          titleIconColor: 'error',
          title: 'Treatments not validated!',
          body: 'You must have at least one treatment for this encounter and all encounter treatments must be validated before generating automatic text.',
          cancel: false,
        })

        return
      }
      let illnessHistory = ''
      illnessHistory += this.generateNonCovidHPI(illnessHistory)
      this.appendHpi(illnessHistory)
    },
    appendHpi(illnessHistory = '') {
      this.present_illness_history = this.$custom.clean(illnessHistory, 'Illness History')
      this.$emit('input', this.present_illness_history)
    },
  },
}
</script>
