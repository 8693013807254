<template>
  <NoteSection
    label="Risk Factors"
  >
    <!-- Old display method, changed to multi-select list box for higher user input speed
    <select-box
      v-for="(item, i) in risk_factors"
      :key="`item-${i}-${risk_factors.length}`"
      v-model="risk_factors[i]"
      :items="riskFactors"
      :append-icon="icons.mdiCloseThick"
      label="Select from list"
      class="col-sm-4 risk-factors"
      clearable="false"
      hide-details
      return-object
      @click:append="risk_factors.splice(i, 1)"
    ></select-box>
    <div class="col-sm-4">
      <btn
        label="Add Risk Factor"
        class="risk-factors"
        :icon="icons.mdiTablePlus"
        :disabled="isSigned"
        @click="risk_factors.push({id:null})"
      ></btn>
    </div>
    -->
    <select-box
      v-model="risk_factors"
      :items="riskFactors"
      label="Choose risk factors"
      class="col-sm-12"
      multiple
      return-object
    ></select-box>
    <text-area
      v-model="risk_factors_other"
      label="Enter other risk factors..."
      class="col-sm-12"
      counter="1200"
      maxlength="1200"
    ></text-area>
  </NoteSection>
</template>

<script>
import { mdiCloseThick, mdiTablePlus } from '@mdi/js'
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Array,
      default: null,
    },
    riskFactorsOther: {
      type: String,
      default: null,
    },
    isSigned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      risk_factors: [...this.value],
      risk_factors_other: this.riskFactorsOther,
      icons: { mdiTablePlus, mdiCloseThick },
    }
  },
  computed: {
    ...mapGetters('encounters', ['riskFactors']),
  },
  watch: {
    risk_factors() {
      this.$emit('input', this.risk_factors)
    },
    risk_factors_other() {
      this.$emit('update:risk-factors-other', this.risk_factors_other)
    },
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.risk-factors {
  margin-bottom: 15px !important;
  &.v-btn {
    width: 100%;
    margin-bottom: 19px !important;
  }
}
</style>
