<template>
  <NoteSection
    label="Vascular Exam"
  >
    <btn
      label="Tap here to assess patient pulses"
      :icon="icons.mdiHeartPulse"
      class="col-sm-12"
      @click="modalState = true"
    ></btn>

    <!-- pulse assessment modal -->
    <v-dialog
      v-model="modalState"
      width="700"
      persistent
      @click:outside="clickOutside()"
    >
      <v-card class="pulse-assessment">
        <v-card-title ref="pulseTitle">
          Vascular Exam
        </v-card-title>
        <v-card-subtitle class="px-5 pt-2 pb-2">
          The following pulses were assessed on today's visit:
        </v-card-subtitle>

        <v-expand-transition>
          <div v-show="isNonPalpable">
            <alert class="mx-2 mb-2">
              Non-palpable selected, please address with PCP, or order doppler
            </alert>
          </div>
        </v-expand-transition>

        <v-row class="mx-0 mb-5">
          <!-- femoral pulse -->
          <NoteSection
            label="Femoral Pulse"
          >
            <radio-buttons
              v-model="pulse_assessments.femoral_left"
              :items="palpable"
              label="Left side"
              class="col-sm-6 column"
            ></radio-buttons>
            <radio-buttons
              v-model="pulse_assessments.femoral_right"
              :items="palpable"
              label="Right Side"
              class="col-sm-6 column"
            ></radio-buttons>
          </NoteSection>

          <!-- popliteal pulse -->
          <NoteSection
            label="Popliteal Pulse"
          >
            <radio-buttons
              v-model="pulse_assessments.popliteal_left"
              :items="palpable"
              label="Left side"
              class="col-sm-6 column"
            ></radio-buttons>
            <radio-buttons
              v-model="pulse_assessments.popliteal_right"
              :items="palpable"
              label="Right Side"
              class="col-sm-6 column"
            ></radio-buttons>
          </NoteSection>

          <!-- posterior tibial pulse -->
          <NoteSection
            label="Posterior Tibial Pulse"
          >
            <radio-buttons
              v-model="pulse_assessments.posterior_tibial_left"
              :items="palpable"
              label="Left side"
              class="col-sm-6 column"
            ></radio-buttons>
            <radio-buttons
              v-model="pulse_assessments.posterior_tibial_right"
              :items="palpable"
              label="Right Side"
              class="col-sm-6 column"
            ></radio-buttons>
          </NoteSection>

          <!-- dorsalis pedis pulse -->
          <NoteSection
            label="Dorsalis Pedis Pulse"
          >
            <radio-buttons
              v-model="pulse_assessments.dorsalis_pedis_left"
              :items="palpable"
              label="Left side"
              class="col-sm-6 column"
            ></radio-buttons>
            <radio-buttons
              v-model="pulse_assessments.dorsalis_pedis_right"
              :items="palpable"
              label="Right Side"
              class="col-sm-6 column"
            ></radio-buttons>
          </NoteSection>
        </v-row>

        <v-card-actions>
          <!-- clear -->
          <btn
            label="Clear All"
            color="secondary"
            :icon="icons.mdiCheckboxMultipleBlankCircleOutline"
            :disabled="isSigned"
            @click="clear"
          ></btn>

          <v-spacer></v-spacer>

          <!-- done -->
          <btn
            label="Done"
            :icon="icons.mdiCheck"
            @click="save"
          ></btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </NoteSection>
</template>

<script>
import { mdiCheck, mdiCheckboxMultipleBlankCircleOutline, mdiHeartPulse } from '@mdi/js'

const initialData = {
  femoral_left: null,
  femoral_right: null,
  popliteal_left: null,
  popliteal_right: null,
  posterior_tibial_left: null,
  posterior_tibial_right: null,
  dorsalis_pedis_left: null,
  dorsalis_pedis_right: null,
}

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    isSigned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalState: false,
      pulse_assessments: {
        ...initialData,
        ...this.value,
      },
      palpable: [
        { title: 'Palpable: 1+', value: 'palpable: 1+' },
        { title: 'Palpable: 2+', value: 'palpable: 2+' },
        { title: 'Non-Palpable', value: 'non-palpable' },
      ],
      icons: { mdiHeartPulse, mdiCheckboxMultipleBlankCircleOutline, mdiCheck },
    }
  },
  computed: {
    isNonPalpable() {
      return this.pulse_assessments.femoral_left === 'non-palpable'
        || this.pulse_assessments.femoral_right === 'non-palpable'
        || this.pulse_assessments.popliteal_left === 'non-palpable'
        || this.pulse_assessments.popliteal_right === 'non-palpable'
        || this.pulse_assessments.posterior_tibial_left === 'non-palpable'
        || this.pulse_assessments.posterior_tibial_right === 'non-palpable'
        || this.pulse_assessments.dorsalis_pedis_left === 'non-palpable'
        || this.pulse_assessments.dorsalis_pedis_right === 'non-palpable'
    },
  },
  watch: {
    modalState() {
      this.$emit('input', this.pulse_assessments)

      // Scroll to top of modal
      const modalTitle = this.$refs.pulseTitle
      if (modalTitle) {
        modalTitle.scrollIntoView(true)
      }
    },
  },
  methods: {
    clear() {
      this.$root.confirm({
        title: 'Clear All Pulse Assessments?',
        body: 'Are you sure you wish to clear all pulse assessments?',
        cancel: 'No',
        confirm: 'Yes',
      }).then(result => {
        if (result) {
          this.pulse_assessments = {
            femoral_left: null,
            femoral_right: null,
            popliteal_left: null,
            popliteal_right: null,
            posterior_tibial_left: null,
            posterior_tibial_right: null,
            dorsalis_pedis_left: null,
            dorsalis_pedis_right: null,
          }
        }
      })
    },
    save() {
      this.modalState = false
    },
    clickOutside() {
      this.save()
    },
  },
}
</script>

<style lang="scss">
.pulse-assessment > div.row > * {
  flex: 1 0 34%;
  margin-bottom: 0 !important;
}
</style>
